body {
    /* background-image: url('/images/statistics-1020319__340.png');*/
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.background-image {
    /*background-image: url("../images/statistics-1020319__340.png");*/
    position: fixed;
    background-repeat: no-repeat;
    -ms-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    /*-ms-opacity: .22;
    opacity: 0.22;*/
}
div.login {
    /*position: fixed;
    top: 0px;
    left: 30%;*/
    max-width: 400px;
    max-height: 500px;
    /*width: 50%;*/
    /*height: 50%;*/
    margin: 30px auto;
    border: 1px solid lightgray;
    border-radius: 3px 4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    background-color: white;
}
div.login .header{
    background: #59b9bc;
    padding: 15px;
    color: white;
    text-align: center;
    font-size:14pt;
}
div.login .lang{
    padding-top:5px;
    margin-right:5px;
    text-align:right;
}
    div.login .lang a {
        padding: 3px;
        padding-right: 5px;
        padding-left: 5px;
        border: 1px solid lightgray;
        border-radius: 3px;
    }
div.login h3{
  text-align:center;
  color: darkcyan;
}
    div.login form {
        padding-left:10px;
        padding-right:10px;
    }
div.login .buttonLogin{
    text-align:center;
    margin-top:10px;
    margin-bottom:10px;
}
    div.login p {
        text-indent: 20px;
        font-size:smaller;
        color:dimgray;
    }

div.comment {
    /*position: fixed;
    top: 520px;
    left: 15%;
    width: 50%;*/
    /*height: 50%;*/
    /*margin: 30px auto;*/
    /*margin: 30px auto;*/
    /*max-width: 600px;*/
    /*max-height: 500px;*/
    color: black;
}
    div.comment h4 {
        text-align: center;
    }
div.packetDoc {
    /*z-index: 5000;
    position: fixed;
    top: 0px;
    left: 3%;*/
    max-width: 500px;
    max-height: 800px;
  /*width: 50%;
    height: 50%;*/
    margin: 5px auto;
    border: 1px solid lightgray;
    border-radius: 3px 4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    background-color: white;
    color: black;
    width: 800px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: small;
}

    div.packetDoc > .packetDoc-heading {
        background-color: #0067b0;
        color: #fff !important;
        text-decoration: none;
        padding: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    div.packetDoc > .packetDoc-body {
        padding: 10px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }

a.linkLicense span {
    text-decoration: underline;
    font-weight: bold;
    font-size: 12pt;
}
div.version {
    text-align:right;
    font-size:x-small;
}
div.policy {
    text-align: center;
    font-size: smaller;
}
div.appMobile {
    padding: 2px;
    max-width: 400px;
    margin: 30px auto;
    text-align: center;
    border: 1px solid lightgray;
    border-radius: 3px 4px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    background-color: white;
}
