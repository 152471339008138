
@media print {
    @page {
        size: landscape;
    }
    .btn {
        display: none;
    }
    #print-h4{
        display: block;
    }
/*
    h4 {
        display: none;
    }

    #footer {
        display: none;
    }

    .btn-group {
        display: none;
    }
*/
}
