.tableDiv {
    border: 1px solid #3697f6;
    width: auto;
    margin-left: -12px;
    margin-top: 5px;
    display: table
}
.rowHeader {
    border: 1px solid #668db6;
    background-color: #3789d0;
    color: white;
    font-weight: bold;
    display: table-header-group;
}
.rowHeader:nth-child(1) div {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}
.cellHeader {
    border: 1px solid #668db6;
    background-color: #3789d0;
    color: white;
    border-right: 1px solid white;
    display: table-cell;
    width: auto;
    max-width: 91px;
    padding: 1px;
    text-align: center;
    font-size: 9pt;
}
.rowDivTable {
    border: 1px solid #668db6;
    display: table-row
}
.cellDivRowTable {
    border-right: 1px solid #3697f6;
    display: table-cell;
    width: auto;
    font-size: 9pt;
    padding-right: 4px;
    text-align: left;
    border-bottom: 1px solid #3697f6;
}
