/* index.css */

#editOkpolu table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#editOkpolu td,
th {
    border: 1px solid black;
    text-align: left;
    padding: 8px;
}
#editOkpolu th {
    position: sticky;
    background-color: Highlight;
    color: white;
    top: 0;
    opacity: 1;
}

#editOkpolu tr:nth-child(even) {
    background-color: #dddddd;
}
