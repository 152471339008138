@media only screen {
}
@media print {
    @page {
        size: landscape;
    }
    h4 {
        display: none;
    }
    form {
        display: none;
    }
    #footer {
        display: none;
    }

    .btn-group {
        display: none;
    }
}
