/*body {
    background-image: none;
}*/
body #root > .container {
    padding: 0px 15px 0;
}
.footer > .container {
    padding-right: 15px;
    padding-left: 15px;
}
.footer {
    /* position: absolute;
    bottom: 0;
    width: 100%;
    */
    /*height: 35px;*/
    background-color: #f5f5f5;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
}