.background-image {
    background-image: url("../images/statistics-1020319__340.png");
    position: fixed;
    background-repeat: no-repeat;
    -ms-background-size: cover;
    background-size: cover;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    -ms-opacity: .22;
    opacity: 0.22;
}

.cont {
    position: fixed;
    top: 0px;
    left: 0px;
    padding-left: 10%;
    padding-right: 10%;
    height: 100%;
}

.panel {
    width: 600px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: medium;
}

    .panel > .panel-heading {
        background-color: #0067b0;
        color: #fff !important;
        text-decoration: none;
        padding: 10px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    .panel > .panel-body {
        padding: 10px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
