.cbox {
    float: left;
    margin: 5mm;
    padding: 2mm;
    box-shadow: 0px 3px 5px #123456;
    background-color: #ffffff;
    font-weight: bold;
    font-size: 10pt;
    text-align: left;
    vertical-align: central;
    text-decoration: none;
    /*font-variant: small-caps;*/
    border-radius: 10%;
}
    .cbox ul {
        color: #050d7f;
        font-weight: 500;
    }
    .cbox a {
        color: #050d7f;
    }