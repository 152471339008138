div.dogovor_info {
    display: inline-block;
    width: 90%;
}

div.dogovor_bt_edit {
    display: inline-block;
    width: auto;
}

div.dogovor_bt_del {
    display: inline-block;
    width: auto;
}
