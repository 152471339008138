.ulListSpecOrg {
    width: 100%;
    height: 300px;
    overflow: auto;
}

    .ulListSpecOrg li {
        padding-top: 2px;
        padding-bottom: 2px;
    }

        .ulListSpecOrg li div {
            margin-top: 2px;
            margin-bottom: 2px;
        }
