.homeBody {
    background-image: url('../images/home-background.jpg');
    background-repeat: no-repeat, repeat;
    background-position: center;
    background-size: cover;
    padding-top: 50px;
    margin-bottom: 20px;
    padding: 100px;
    border-radius: 50px;
    box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    height: 750px;
}
