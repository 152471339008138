@media only screen {
    .containerDiv {
        border: 1px solid #3697f6;
        width: auto;
        margin-left: -12px;
        margin-top: 5px;
        display: table
    }

    .rowDivHeader {
        border: 1px solid #668db6;
        background-color: #3789d0;
        color: white;
        font-weight: bold;
        display: table-row
    }
    .rowDivHeader:nth-child(1) div {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }

    .rowDiv {
        border: 1px solid #668db6;
        background-color: #edf5fd;
        display: table-row
    }

    .cellDivHeader {
        border: 1px solid #668db6;
        background-color: #3789d0;
        color: white;
        border-right: 1px solid white;
        display: table-cell;
        width: auto;
        max-width: 91px;
        padding: 1px;
        text-align: center;
        font-size: 9pt;
    }

    .cellDivHeaderLong {
        border: 1px solid #668db6;
        background-color: #3789d0;
        color: white;
        border-right: 1px solid white;
        display: table-cell;
        max-width: 300px;
        padding: 1px;
        text-align: center;
        font-weight: bold;
        font-size: 9pt;
    }

    .cellDiv {
        border-right: 1px solid #3697f6;
        display: table-cell;
        width: auto;
        font-size: 9pt;
        padding-right: 4px;
        text-align: center;
        border-bottom: 1px solid #3697f6;
    }

    .cellDivLong {
        border-right: 1px solid #3697f6;
        display: table-cell;
        max-width: 350px;
        font-size: 10pt;
        padding-right: 4px;
        text-align: left;
        border-bottom: 1px solid #3697f6;
    }

    .cellDivH {
        border-right: 1px solid #3697f6;
        display: table-cell;
        width: auto;
        font-size: 9pt;
        padding-right: 4px;
        text-align: center;
        border-bottom: 1px solid #3697f6;
    }

    .cellDivLongH {
        border-right: 1px solid #3697f6;
        display: table-cell;
        max-width: 350px;
        font-size: 9pt;
        padding-right: 4px;
        text-align: right;
        border-bottom: none;
    }


    .lastCell {
        border-right: none;
    }

    .inputData {
        min-width: 90px;
        font-size: 9pt;
        text-align: right;
    }

    input.inputData {
        max-width: 90px;
        font-size: 9pt;
        text-align: right;
    }

    span.inputData {
        max-width: 90px;
        font-size: 9pt;
        text-align: right;
    }

    .clip {
        white-space: nowrap; /* Запрещаем перенос строк */
        overflow: hidden; /* Обрезаем все, что не помещается в область */
        text-overflow: ellipsis; /* Добавляем многоточие */
    }
}

@media print {
    @page {
        size: landscape;
    }

    h4 {
        display: none;
    }

    #footer {
        display: none;
    }

    .btn-group {
        display: none;
    }

    .containerDiv {
        border: 1px solid black;
        width: auto;
        margin-top: 5px;
        display: table
    }

    .rowDivHeader {
        border: 1px solid black;
        font-weight: bold;
        display: table-row
    }

    .rowDiv {
        border: 1px solid black;
        display: table-row
    }

    .cellDivHeader {
        border: 1px solid black;
        display: table-cell;
        width: auto;
        max-width: 91px;
        padding: 1px;
        text-align: center;
        font-size: 8pt;
    }

    .cellDivHeaderLong {
        border: 1px solid black;
        display: table-cell;
        max-width: 300px;
        padding: 1px;
        text-align: center;
        font-weight: bold;
        font-size: 8pt;
    }

    .cellDiv {
        border-right: 1px solid black;
        display: table-cell;
        width: auto;
        max-width: 91px;
        font-size: 10pt;
        padding-right: 4px;
        text-align: center;
        border-bottom: 1px solid black;
    }

    .cellDivLong {
        border-right: 1px solid black;
        display: table-cell;
        max-width: 350px;
        font-size: 10pt;
        padding-right: 4px;
        text-align: right;
        border-bottom: 1px solid black;
    }

    .cellDivH {
        border-right: 1px solid black;
        display: table-cell;
        width: auto;
        max-width: 91px;
        font-size: 8pt;
        padding-right: 4px;
        text-align: center;
        border-bottom: none;
    }

    .cellDivLongH {
        border-right: 1px solid black;
        display: table-cell;
        max-width: 350px;
        font-size: 8pt;
        padding-right: 4px;
        text-align: right;
        border-bottom: none;
    }


    .lastCell {
        border-right: none;
    }

    .inputData {
        max-width: 90px;
        font-size: 10pt;
        text-align: right;
    }

    .clip {
        text-wrap: normal; /* перенос строк */
    }
}
